import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader, PrimaryButton } from '@calm-web/design-system';

import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { usePurchase } from '@/hooks/api/usePurchase';
import { useSubscription } from '@/hooks/api/useSubscription';
import { usePermissions } from '@/hooks/auth';
import { useUser } from '@/hooks/store';
import { Partner } from '@/types/store/reducers';
import { calmLogger } from '@/utils/calmLogger';
import { currencyStringFromCents, formatDate } from '@/utils/helpers';

import CancelPlanModal from './CancelPlanModal';
import ChangePaymentModal from './ChangePaymentModal';
import PaymentDetails from './PaymentDetails';
import ReactivatePlanModal from './ReactivatePlanModal';
import TerminatePlanModal from './TerminatePlanModal';
import { UpdateCoverage } from './UpdateCoverage';
import messages from './messages';
import {
	ButtonContainer,
	CancelButton,
	LoaderContainer,
	LoadingContainer,
	PaymentContainer,
	PlanContainer,
	PlanDetail,
	SectionContainer,
	Subtitle,
	TextBlock,
	Title,
	WebLink,
	SmallText,
} from './styles';
import { BillingDetails } from './types';

interface Props {
	partner: Partner;
}

const supportLink = 'https://support.calm.com/hc/en-us?solvvyProvidedByEmployer';

function TerminateButton({
	setShowTerminateModal,
}: {
	setShowTerminateModal: (b: boolean) => void;
}): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<CancelButton
			backgroundColor="white"
			onPress={(): void => setShowTerminateModal(true)}
			data-testid="terminate-plan-button"
		>
			{formatMessage(messages.terminatePlan)}
		</CancelButton>
	);
}

function CancellationDetails({
	canceledDate,
	expiresDate,
	setShowReactivateModal,
	shouldShowTerminateButton,
	setShowTerminateModal,
}: {
	canceledDate: string;
	expiresDate: string;
	setShowReactivateModal: (b: boolean) => void;
	shouldShowTerminateButton: boolean;
	setShowTerminateModal: (b: boolean) => void;
}): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<>
			<PlanDetail>
				{formatMessage(messages.canceledAt, {
					canceledat: canceledDate,
				})}
			</PlanDetail>
			<PlanDetail>
				{formatMessage(messages.expiresAt, {
					expiresat: expiresDate,
				})}
			</PlanDetail>
			<ButtonContainer>
				<PrimaryButton
					backgroundColor="blue3"
					data-testid="reactivate-plan-button"
					onPress={(): void => setShowReactivateModal(true)}
				>
					{formatMessage(messages.reactivatePlan)}
				</PrimaryButton>
				{shouldShowTerminateButton && <TerminateButton setShowTerminateModal={setShowTerminateModal} />}
			</ButtonContainer>
		</>
	);
}

function CurrentPlanDetails({
	expiresDate,
	nextBillAmount,
	partner,
	setShowCancelModal,
	setShowTerminateModal,
	shouldShowTerminateButton,
}: {
	expiresDate: string;
	nextBillAmount: string;
	partner: Partner;
	setShowCancelModal: (b: boolean) => void;
	setShowTerminateModal: (b: boolean) => void;
	shouldShowTerminateButton: boolean;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const { data: flagValues } = useFeatureFlags('b2b-self-serve-tax-collection');
	const selfServeTaxCollection = flagValues && flagValues['b2b-self-serve-tax-collection'];
	const [hasValidPermissions, actions] = usePermissions();
	const isAccountManager = hasValidPermissions('downgrade_selfserve_plan', [actions.UPDATE]);

	return (
		<>
			<PlanDetail>
				{formatMessage(messages.renewalCadence, {
					cadence: 'Yearly',
				})}
			</PlanDetail>
			<PlanDetail>
				{formatMessage(messages.nextDate, {
					billdate: expiresDate,
				})}
			</PlanDetail>
			<PlanDetail>
				{formatMessage(messages.nextAmount, {
					billamount: nextBillAmount,
				})}
				{selfServeTaxCollection && <SmallText>{formatMessage(messages.plusApplicableTaxes)}</SmallText>}
			</PlanDetail>
			<ButtonContainer>
				<UpdateCoverage
					coveredLives={partner?.contract_covered_lives}
					partner={partner}
					isAccountManager={isAccountManager}
				/>
				<CancelButton
					backgroundColor="white"
					onPress={(): void => setShowCancelModal(true)}
					data-testid="cancel-plan-button"
				>
					{formatMessage(messages.cancelPlan)}
				</CancelButton>
				{shouldShowTerminateButton && <TerminateButton setShowTerminateModal={setShowTerminateModal} />}
			</ButtonContainer>
		</>
	);
}

export function PlanDetailsInfoMessage(): ReactElement | null {
	const { formatMessage } = useIntl();

	return (
		<TextBlock>
			{formatMessage(messages.support, {
				link: (...chunks: ReactNode[]) => (
					<WebLink data-testid="contact-support" href={supportLink} target="_blank" rel="noopener noreferrer">
						{chunks}
					</WebLink>
				),
				linebreak: <br />,
			})}
		</TextBlock>
	);
}

export default function PlanDetails({ partner }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { user } = useUser();
	const isAdmin = user?.accessPolicy?.isAdmin;
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showReactivateModal, setShowReactivateModal] = useState(false);
	const [showChangeModal, setShowChangeModal] = useState(false);
	const [showTerminateModal, setShowTerminateModal] = useState(false);
	const [hasValidPermissions, actions] = usePermissions();
	const { data: subscription, loading, error } = useSubscription(partner.id);
	const [billingDetails, setBillingDetails] = useState<BillingDetails>({
		name: '',
		brand: undefined,
	});
	const {
		handlePaymentChange,
		isUpdatingPayment,
		getPaymentInfo,
		loadingPaymentInfo,
		paymentInfo,
		paymentError,
	} = usePurchase();

	const onCloseCancelModal = (): void => {
		setShowCancelModal(false);
	};

	const onCloseReactivateModal = (): void => {
		setShowReactivateModal(false);
	};

	const onCloseChangeModal = (): void => {
		setShowChangeModal(false);
	};

	const onCloseTerminateModal = (): void => {
		setShowTerminateModal(false);
	};

	useEffect(() => {
		getPaymentInfo().catch(err => calmLogger.error('Error in PlanDetails getPaymentInfo', {}, err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<LoaderContainer>
				<Loader color="gray1" />
			</LoaderContainer>
		);
	}

	if (error || !subscription) {
		return <div>{formatMessage(messages.subscriptionErrorMessage)}</div>;
	}

	const { amount, quantity, canceled_at, expires } = subscription;
	const rawPricePerCoveredLife = amount / quantity;
	const pricePerCoveredLife = currencyStringFromCents(rawPricePerCoveredLife);

	const nextBillAmount = currencyStringFromCents(amount);
	const canceledDate = formatDate(new Date(canceled_at || 0));
	const expiresDate = formatDate(new Date(expires || 0));
	const planDetails = {
		coveredLives: quantity,
		costPerLife: pricePerCoveredLife,
		total: currencyStringFromCents(amount),
		expires,
	};

	const shouldShowTerminateButton = hasValidPermissions('terminate_partner', [actions.CREATE]);

	return (
		<SectionContainer>
			<PlanContainer>
				<Title>{formatMessage(messages.yourPlan)}</Title>
				<Subtitle>
					{formatMessage(messages.planInfo, {
						boldtext: (...chunks: ReactNode[]) => <strong>{chunks}</strong>,
						lives: partner?.contract_covered_lives,
						cost: pricePerCoveredLife,
					})}
				</Subtitle>
				{canceled_at ? (
					<CancellationDetails
						canceledDate={canceledDate}
						expiresDate={expiresDate}
						setShowReactivateModal={setShowReactivateModal}
						setShowTerminateModal={setShowTerminateModal}
						shouldShowTerminateButton={Boolean(isAdmin)}
					/>
				) : (
					<CurrentPlanDetails
						expiresDate={expiresDate}
						nextBillAmount={nextBillAmount}
						partner={partner}
						setShowCancelModal={setShowCancelModal}
						setShowTerminateModal={setShowTerminateModal}
						shouldShowTerminateButton={shouldShowTerminateButton}
					/>
				)}
				<PlanDetailsInfoMessage />
			</PlanContainer>

			{loadingPaymentInfo ? (
				<LoadingContainer>
					<Loader />
				</LoadingContainer>
			) : (
				<PaymentContainer>
					<Title>{formatMessage(messages.paymentDetails)}</Title>

					<PaymentDetails
						paymentInfo={paymentInfo}
						paymentError={paymentError}
						setShowChangeModal={setShowChangeModal}
					/>
				</PaymentContainer>
			)}

			{showCancelModal && (
				<CancelPlanModal onModalDismiss={onCloseCancelModal} expirationDateString={expiresDate} />
			)}
			{showTerminateModal && <TerminatePlanModal onModalDismiss={onCloseTerminateModal} />}
			{showChangeModal && (
				<ChangePaymentModal
					onModalDismiss={onCloseChangeModal}
					isUpdatingPayment={isUpdatingPayment}
					handleSubmit={() => handlePaymentChange({ name: billingDetails.name, setShowChangeModal })}
					billingDetails={billingDetails}
					setBillingDetails={setBillingDetails}
				/>
			)}
			{showReactivateModal && (
				<ReactivatePlanModal
					planDetails={planDetails}
					billingDetails={billingDetails}
					setBillingDetails={setBillingDetails}
					onModalDismiss={onCloseReactivateModal}
					getPaymentInfo={getPaymentInfo}
					paymentInfo={paymentInfo}
					paymentError={paymentError}
					setShowChangeModal={setShowChangeModal}
				/>
			)}
		</SectionContainer>
	);
}
